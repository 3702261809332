import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "Components";
import { useTranslation } from "react-i18next";
import * as action from "Services/redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import { GetPoliciesSpecificValues } from "Services/OtherApis";

function CreateTermsAndRates({ setModelOpen, data, allData }) {
  console.log("all data", allData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [policyMinValue, setPolicyMinValue] = useState("");
  const [policyMaxValue, setPolicyMaxValue] = useState("");

  const [adminFee, setAdminFee] = useState("");
  const [annualRate, setAnnualRate] = useState("");
  const [vat, setVat] = useState("");
  const [rateMonthly, setRateMonthly] = useState("");
  const [term, setTerm] = useState("");
  const [termRate, setTermRate] = useState("");

  // Error states for each field
  const [adminFeeError, setAdminFeeError] = useState("");
  const [annualRateError, setAnnualRateError] = useState("");
  const [vatError, setVatError] = useState("");
  const [rateMonthlyError, setRateMonthlyError] = useState("");
  const [termError, setTermError] = useState("");
  const [termRateError, setTermRateError] = useState("");

  const message = useSelector((state) => state.message);
  const error = useSelector((state) => state.error);

  const validateAdminFee = (value) => {
    if (!value) return "Admin Fee is required";
    if (isNaN(value) || parseFloat(value) <= 0)
      return "Must be a positive number";
    return "";
  };

  const validateAnnualRate = (value) => {
    if (!value) return "Annual Rate is required";
    if (isNaN(value) || parseFloat(value) <= 0)
      return "Must be a positive number";
    return "";
  };

  const validateVat = (value) => {
    if (!value) return "VAT is required";
    if (isNaN(value) || parseFloat(value) < 0)
      return "Must be a valid percentage";
    return "";
  };

  const validateRateMonthly = (value) => {
    if (!value) return "Flat Rate Monthly is required";
    if (isNaN(value) || parseFloat(value) <= 0)
      return "Must be a positive number";
    return "";
  };

  const validateTerm = (value) => {
    if (!value) return "Term is required";
    if (isNaN(value) || parseFloat(value) <= 0)
      return "Term must be a positive number";

    if (
      parseFloat(value) < parseFloat(policyMinValue) ||
      parseFloat(value) > parseFloat(policyMaxValue)
    ) {
      return `Term must be between ${policyMinValue} and ${policyMaxValue}`;
    }

    // Check if the term already exists in allData
    const termExists = allData?.some((item) => item.term === parseFloat(value));
    if (termExists) {
      return "This term is already used";
    }

    return "";
  };

  const validateTermRate = (value) => {
    if (!value) return "Term Rate is required";
    if (isNaN(value) || parseFloat(value) <= 0)
      return "Must be a positive number";
    return "";
  };

  const validateFields = () => {
    let isValid = true;

    // Admin Fee Validation
    if (!adminFee) {
      setAdminFeeError("Admin Fee is required");
      isValid = false;
    } else if (isNaN(adminFee) || parseFloat(adminFee) <= 0) {
      setAdminFeeError("Admin Fee must be a positive number");
      isValid = false;
    } else {
      setAdminFeeError("");
    }

    // Annual Rate Validation
    if (!annualRate) {
      setAnnualRateError("Annual Rate is required");
      isValid = false;
    } else if (isNaN(annualRate) || parseFloat(annualRate) <= 0) {
      setAnnualRateError("Annual Rate must be a positive number");
      isValid = false;
    } else {
      setAnnualRateError("");
    }

    // VAT Validation
    if (!vat) {
      setVatError("VAT is required");
      isValid = false;
    } else if (isNaN(vat) || parseFloat(vat) < 0) {
      setVatError("VAT must be a valid percentage");
      isValid = false;
    } else {
      setVatError("");
    }

    // Flat Rate Monthly Validation
    if (!rateMonthly) {
      setRateMonthlyError("Flat Rate Monthly is required");
      isValid = false;
    } else if (isNaN(rateMonthly) || parseFloat(rateMonthly) <= 0) {
      setRateMonthlyError("Flat Rate Monthly must be a positive number");
      isValid = false;
    } else {
      setRateMonthlyError("");
    }

    // Term Validation
    if (!term) {
      setTermError("Term is required");
      isValid = false;
    } else if (isNaN(term) || parseFloat(term) <= 0) {
      setTermError("Term must be a positive number");
      isValid = false;
    } else if (
      parseFloat(term) < parseFloat(policyMinValue) ||
      parseFloat(term) > parseFloat(policyMaxValue)
    ) {
      setTermError(
        `Term must be between ${policyMinValue} and ${policyMaxValue}`
      );
      isValid = false;
    } else {
      setTermError("");
    }

    // Term Rate Validation
    if (!termRate) {
      setTermRateError("Term Rate is required");
      isValid = false;
    } else if (isNaN(termRate) || parseFloat(termRate) <= 0) {
      setTermRateError("Term Rate must be a positive number");
      isValid = false;
    } else {
      setTermRateError("");
    }

    return isValid;
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (validateFields()) {
      CreateNewUser();
    }
  }

  function CreateNewUser() {
    const temp = {
      adminFeeInPercentage: parseFloat(adminFee),
      annualRate: parseFloat(annualRate),
      flatRateMonthly: parseFloat(rateMonthly),
      term: parseFloat(term),
      termRate: parseFloat(termRate),
      vatInPercentage: parseFloat(vat),
    };

    if (data) {
      dispatch({
        type: "UPDATE_TERM_AND_RATES",
        payload: { ...temp, id: data?.id },
      });
    } else {
      dispatch({ type: "ADD_NEW_TERM_AND_RATES", payload: temp });
    }
    setTimeout(() => setModelOpen(false), 700);
  }

  useEffect(() => {
    GetPolicies();
  }, []);

  function GetPolicies() {
    const temp = "policies=min_loan_tenure&policies=max_loan_tenure";
    GetPoliciesSpecificValues(temp).then((res) => {
      if (res.status === 200) {
        const min = res?.response?.data.find(
          (name) => name.policyName === "min_loan_tenure"
        );
        const max = res?.response?.data.find(
          (name) => name.policyName === "max_loan_tenure"
        );
        setPolicyMinValue(min?.policyValue);
        setPolicyMaxValue(max?.policyValue);
      }
    });
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center justify-center"
    >
      <div className=" bg-white dark:bg-dark2 rounded shadow-sm  rtl:space-x-reverse flex flex-col lg:flex-row   w-full lg:w-max lg:space-x-10 lg:px-10 px-4 py-5 ">
        <div className="flex flex-col  space-y-5">
          <div className="flex flex-row w-full space-x-8 mt-5 rtl:space-x-reverse">
            <InputField
              heading={t("Admin Fee In Percentage")}
              value={adminFee}
              onChange={setAdminFee}
              error={adminFeeError}
              setError={setAdminFeeError}
              validate={validateAdminFee}
            />
            <InputField
              heading={t("Annual Rate")}
              value={annualRate}
              onChange={setAnnualRate}
              error={annualRateError}
              setError={setAnnualRateError}
              validate={validateAnnualRate}
            />
          </div>
          <div className="flex flex-row w-full space-x-8 mt-5 rtl:space-x-reverse">
            <InputField
              heading={t("VAT In Percentage")}
              value={vat}
              onChange={setVat}
              error={vatError}
              setError={setVatError}
              validate={validateVat}
            />
            <InputField
              heading={t("Flat Rate Monthly")}
              value={rateMonthly}
              onChange={setRateMonthly}
              error={rateMonthlyError}
              setError={setRateMonthlyError}
              validate={validateRateMonthly}
            />
          </div>
          <div className="flex flex-row w-full space-x-8 mt-5 rtl:space-x-reverse">
            <InputField
              heading={t("Term")}
              value={term}
              onChange={setTerm}
              error={termError}
              setError={setTermError}
              validate={validateTerm}
            />
            <InputField
              heading={t("Term Rate")}
              value={termRate}
              onChange={setTermRate}
              error={termRateError}
              setError={setTermRateError}
              validate={validateTermRate}
            />
          </div>
          <div className="flex justify-end mt-10 mb-5 px-14">
            <Button
              type="submit"
              buttonValue={data ? t("Update") : t("Submit")}
              buttonStyle="px-20 py-2 w-full"
              buttonDisable={
                adminFeeError ||
                annualRateError ||
                vatError ||
                rateMonthlyError ||
                termError ||
                termRateError
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
}

function InputField({
  heading,
  value,
  onChange,
  error,
  setError,
  type,
  validate,
}) {
  const handleChange = (e) => {
    let newValue = e.target.value;

    // Ensure only positive numbers are allowed
    if (newValue !== "") newValue = Math.abs(newValue);
    onChange(newValue);

    // Real-time validation
    if (validate) {
      const errorMessage = validate(newValue);
      setError(errorMessage);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <label className="text-sm text-gray-700 dark:text-dark0">{heading}</label>
      <input
        type={type || "number"}
        value={value}
        onChange={handleChange}
        className={`border rounded-md px-3 py-1.5 outline-none mt-2 w-full dark:bg-dark1 dark:text-dark0 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      {error && <span className="text-red-500 text-xs mt-1">{error}</span>}
    </div>
  );
}

export default CreateTermsAndRates;
