import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Model } from "Components";
import Model2 from "Components/Model2";
import withAuthorization from "constants/authorization";
import CreateBankCode from "./Model/addCustomerToBlackList";
import {
  GetSeulahBlackListedCustomers,
  RemoveBlackListCustomer,
} from "Services/OtherApis";
import * as action from "Services/redux/reducer";
import { useTranslation } from "react-i18next";
import Table from "Components/Table";

function BlackListCustomers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpen2, setModelOpen2] = useState(false);
  const [activeDelete, setActiveDelete] = useState();
  const [data, setData] = useState([]);

  function reset() {
    setModelOpen(false);
  }

  useEffect(() => {
    getList();
  }, []);
  function getList() {
    dispatch(action.Loading({ Loading: true }));
    GetSeulahBlackListedCustomers()
      .then((data) => {
        if (!data?.error) {
          if (data && data?.response && data?.response?.length > 0) {
            setData(data?.response);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(action.Loading({ Loading: false }));
      });
  }
  function DeleteCustomer() {
    dispatch(action.Loading({ Loading: true }));

    RemoveBlackListCustomer(activeDelete)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            action.Message({
              message: res?.data || "Success",
              open: true,
              error: false,
            })
          );
          setModelOpen2(false);
          getList();
        } else {
          dispatch(
            action.Message({
              message: "Server Error",
              open: true,
              error: true,
            })
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(action.Loading({ Loading: false }));
      });
  }

  let header = [
    { name: "Id" },
    { name: "Id Number" },
    { name: "Reason English" },
    { name: "Reason Arabic" },
    { name: "Action" },
  ];
  return (
    <div className="  bg-white dark:bg-dark2 border  w-full rounded-lg mt-4 md:mt-0 p-4">
      <div className="flex flex-row  overflow-x-auto  justify-between items-center">
        <h1 className="dark:text-dark0 font-bold">
          {t("Blacklisted Customers")}
        </h1>
        <div className="space-x-4 rtl:space-x-reverse">
          <Button
            onButtonClick={() => setModelOpen(true)}
            buttonValue={t("Add Customer to Blacklist")}
            buttonStyle="px-20 py-2"
          />
        </div>
      </div>

      <div className="  w-full">
        <div className="overflow-x-auto relative">
          {data?.length > 0 ? (
            <Table header={header} styleMain={"mt-0"}>
              <tbody className="dark:text-dark0">
                {data?.map((v, k) => (
                  <tr
                    key={k}
                    className={`border-b   border-gray-100 dark:border-dark1 ${
                      k % 2
                        ? "bg-gray-50 dark:bg-gray-600"
                        : "bg-white dark:bg-gray-700"
                    }`}
                  >
                    <td className="px-3 py-4">{k + 1}</td>
                    <td className="px-3 py-4 ">{v?.idNumber}</td>
                    <td className="px-3 py-4">{v?.reasonInEnglish}</td>
                    <td className="px-3 py-4 amiri-regular">
                      {v?.reasonInArabic}
                    </td>

                    <td
                      className="px-3 py-4"
                      onClick={() => (
                        setModelOpen2(true), setActiveDelete(v?.idNumber)
                      )}
                    >
                      <div className="bg-red-500 rounded-md px-4 py-1 text-white text-center items-center w-min hover:bg-opacity-80 cursor-pointer duration-300">
                        {t("Delete")}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="py-20 px-20 text-center w-full dark:text-dark0">
              <a>{t("Nothing To Show!")}</a>
            </div>
          )}
        </div>
      </div>

      {modelOpen ? (
        <Model2
          setModelOpen={(e) => setModelOpen(e)}
          reset={() => reset()}
          heading={t("Add Customer to Blacklist")}
        >
          <CreateBankCode
            setModelOpen={(e) => setModelOpen(e)}
            getBlackListCustomers={() => getList()}
          />
        </Model2>
      ) : null}

      <Model
        heading={t("Remove Customer from Blacklist")}
        isOpen={modelOpen2}
        style="w-1/3"
        innerStyle="py-10"
        setState={() => setModelOpen2(!modelOpen2)}
        action1Value={t("Cancel")}
        action2Value={t("Delete")}
        action2={() => DeleteCustomer()}
        action1={() => setModelOpen2(!modelOpen)}
      >
        <a className=" text-xl text-gray-800 dark:text-white">
          {t("Are you sure to Whitelist this Customer ?")}
          <span className="font-semibold"> </span>
        </a>
      </Model>
    </div>
  );
}

export default withAuthorization(BlackListCustomers);
