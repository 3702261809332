import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { Tooltip } from "Components";
import { getLanguage } from "functions/getLanguage";
import { UpdateSimahProductStatus } from "Services/OtherApis";
import * as action from "Services/redux/reducer";

function SimahProductList({ data, setData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const UpdateProductStatus = useCallback(
    async (product, type) => {
      const updatedStatus = {
        enableDbr: type === "dbr" ? !product.enableDbr : product.enableDbr,
        enablePerformance:
          type === "performance"
            ? !product.enablePerformance
            : product.enablePerformance,
      };

      dispatch(action.Loading({ Loading: true }));

      try {
        const response = await UpdateSimahProductStatus(product, updatedStatus);

        if (response.status === 200 || response.status === 201) {
          setData(product.id, updatedStatus);
        } else {
          throw new Error("Failed to update product status");
        }
      } catch (error) {
        dispatch(
          action.Message({
            message: error.message || "An error occurred",
            open: true,
            error: true,
          })
        );
      } finally {
        dispatch(action.Loading({ Loading: false }));
      }
    },
    [dispatch, setData]
  );

  return (
    <div className="w-full">
      <div className="overflow-x-auto relative py-1">
        <table
          className={`w-full whitespace-nowrap  text-sm  text-gray-500 dark:text-gray-400 ${
            getLanguage() === "ar" ? "text-right" : "text-left"
          }`}
        >
          <thead className="text-xs text-gray-400 uppercase bg-gray-100 dark:bg-dark3 font-normal">
            <tr>
              <th scope="col" className="px-10 py-3 cursor-pointer">
                {t("Id")}
              </th>
              <th scope="col" className="px-3 py-3">
                {t("Serial Number")}
              </th>
              <th scope="col" className="px-3 py-3 cursor-pointer">
                {t("Code")}
              </th>
              <th scope="col" className="px-3 py-3 cursor-pointer">
                {t("Description")}
              </th>
              <th scope="col" className="px-3 py-3 cursor-pointer">
                {t("Arabic")}
              </th>
              <th scope="col" className="px-3 py-3">
                {t("Product Group")}
              </th>
              <th scope="col" className="px-3 py-3 cursor-pointer">
                {t("Product Category")}
              </th>

              <th
                scope="col"
                className="px-3 py-3 cursor-pointer flex flex-row items-center space-x-1"
              >
                <div>{t("Dbr")}</div>
                <Tooltip text="Dbr">
                  <HiOutlineInformationCircle className="text-lg" />
                </Tooltip>
              </th>
              <th scope="col" className="px-3 py-3 cursor-pointer">
                <div className="flex flex-row cursor-pointer items-center space-x-1">
                  <div>{t("Performance")}</div>
                  <Tooltip text={t("Performance")}>
                    <HiOutlineInformationCircle className="text-lg" />
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="dark:text-dark0">
            {data?.map((v, k) => (
              <tr
                key={k}
                className={`border-b   border-gray-100 dark:border-dark1 ${
                  k % 2
                    ? "bg-gray-50 dark:bg-gray-600"
                    : "bg-white dark:bg-gray-700"
                }`}
              >
                <td className="px-10 py-4">{v.id}</td>
                <td className="px-3 py-4">{v.serialNumber}</td>
                <td className="px-3 py-4">{v.code}</td>
                <td className="px-3 py-4">{v.description}</td>
                <td className="px-3 py-4 amiri-regular">{v.arabic}</td>
                <td className="px-3 py-4">{v.productGroup}</td>
                <td className="px-3 py-4">{v.productCategory}</td>
                <td className="px-3 py-4">
                  <div
                    className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
                      v?.enableDbr
                        ? "bg-green-500 justify-end"
                        : "bg-red-500 justify-start"
                    }`}
                    onClick={() => UpdateProductStatus(v, "dbr")}
                  >
                    <div className="bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300" />
                  </div>
                </td>
                <td className="px-3 py-4 flex items-center  justify-center">
                  <div
                    className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
                      v?.enablePerformance
                        ? "bg-green-500 justify-end"
                        : "bg-red-500 justify-start"
                    }`}
                    onClick={() => UpdateProductStatus(v, "performance")}
                  >
                    <div className="bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SimahProductList;
