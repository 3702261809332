import React, { useState } from "react";
import Gosi from "./companyTabs/gosi";
import Absher from "./companyTabs/absher";
import Alrajhi from "./companyTabs/Alrajhi";

import Nafath from "./companyTabs/nafath";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import NafithSanad from "./companyTabs/NafithSanad";
import Nafith from "./companyTabs/nafith";
import Lean from "./companyTabs/Lean";
import Aml from "./companyTabs/amlDetails";
import Emdah from "./companyTabs/Emdah";
import SimahReport from "./companyTabs/simahReport";
function Template() {
  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");
  const user = queryParams.get("user");

  const [state, setState] = useState("Absher Detail");

  const getTab = () => {
    const activeItem = data.find((item) => item.label === state);
    return activeItem
      ? activeItem.tab
      : "Select an item to see the description.";
  };
  function setNavigation(stateValue) {
    setState(stateValue);
  }
  useEffect(() => {
    getGosi();
  }, []);
  function getGosi() {
    dispatch({
      type: "GET_GOSI_API",
      payload: { user, id },
    });
  }
  return (
    <div className="  bg-white dark:bg-dark2 border border-primary w-full rounded-lg ">
      <div className="flex flex-row   overflow-scroll">
        {data?.map((v, k) => {
          return (
            <div
              onClick={() => setNavigation(v.label)}
              className={`px-4 cursor-pointer ${
                state === v.label
                  ? "text-primary "
                  : "text-gray-600 dark:text-dark0"
              }`}
            >
              <div
                className={`px-3 py-4  w-max  ${
                  state === v.label ? "border-primary border-b-2" : " "
                }`}
              >
                <a className="text-sm">{v.label}</a>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className=" w-full bg-gray-200 dark:bg-dark1"
        style={{ height: 1, marginTop: -1 }}
      ></div>
      <div className="flex flex-row space-x-5 ">{getTab()}</div>
    </div>
  );
}
export default Template;

const data = [
  {
    label: "Absher Detail",
    tab: <Absher />,
  },
  {
    label: "Nafath Detail",
    tab: <Nafath />,
  },
  {
    label: "Gosi",
    tab: <Gosi />,
  },
  {
    label: "Simah",
    tab: <SimahReport />,
  },
  {
    label: "Aml Details",
    tab: <Aml />,
  },
  {
    label: "Al RAJHI",
    tab: <Alrajhi />,
  },
  {
    label: "IVR",
    tab: <p>Ivr</p>,
  },
  {
    label: "Selaa Buy",
    tab: <p>Selaa buy</p>,
  },

  {
    label: "Emdah Agreement",
    tab: <Emdah />,
  },
  {
    label: "Nafith",
    tab: <Nafith />,
  },
  {
    label: "Promissory Note",
    tab: <NafithSanad />,
  },

  {
    label: "Lean",
    tab: <Lean />,
  },
];
