export const CODE = {
  OVERVIEW: "overview",
  OVERVIEW_ACCOUNT: "overview_account",
  APPLICATIONS: "applications",
  LOAN_APPLICATIONS: "loan_applications",
  CUSTOMERS: "customers",
  CUSTOMER_DASHBOARD: "customers_dashboard",
  ALL_CUSTOMERS: "all_customers",
  VERIFIED_CUSTOMERS: "verified_customers",
  ADMINISTRATOR: "administrator",
  CREATE_ADMIN: "create_admin",
  ASSIGN_PERMISSIONS_TO_ROLE: "assign_permissions_to_roles",
  SEELA: "seela",
  SEELA_HISTORY: "seela_history",
  SEELA_TRANSACTION: "seela_transaction",
  SEELA_COMMODITY: "seela_commodity",
  SEELA_WALLET: "seela_wallet",
  POLICIES: "policies",
  VIEW_POLICIES: "view_policies",
  UPDATE_POLICY: "update_policy",
  APPROVE_POLICY: "approve_policy",
  REJECT_POLICY: "reject_policy",
  LOAN_MANAGEMENT: "loan_management",
  CREATE_TYPE: "create_type",
  CUSTOMER_EMI: "customer_emi",
  DECISIONS: "decisions",
  QUESTIONS: "questions",
  CREATE_SET: "create_set",
  CREATE_DECISION: "create_decisions",
  NOTIFICATIONS: "notifications",
  NOTIFICATIONS_DASHBOARD: "notifications_dashboard",
  TERMS_AND_CONDITIONS: "terms_and_conditions",
  AWARNESS_MESSAGE: "awareness_messages",
  ADD_SMS: "add_sms",
  SIMAH: "simah",
  SIMAH_DASHBOARD: "simah_dashboard",
  SIMAH_BLACKLISTED_COIUNTRIES: "simah_blacklisted_countries",
  CALCULATIONS: "calculations",
  CALCULATIONS_DBR: "calculations_dbr",
  CALCULATION_BARE_MINIMUM_EXPENSE: "calculations_bare_minimum_expenses",
  CALCULATIONS_TERMS_AND_RATES: "calculations_terms_and_rates",

  SEULAH_BANK_ACCOUNT_DETAILS: "seulah_bank_account_details",
  DISBURSEMENT_ACCOUNT: "disbursement_account",
  REPAYMENT_ACCOUNT: "repayment_account",
  SEULAH_BANK_CODES: "seulah_bank_codes",
  BLACKLISTED_CUSTOMERS: "blacklisted_customers",
};
