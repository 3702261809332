import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table from "Components/Table";

function Calculations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("user");
  const getAllUsersEmi = useSelector((state) => state.getAllUsersEmi);
  const loading = useSelector((state) => state.Loading);
  useEffect(() => {
    getAllUsersEmiData();
  }, []);
  function getAllUsersEmiData() {
    dispatch({
      type: "GET_ALL_USERS_EMI",
    });
  }
  useEffect(() => {
    if (getAllUsersEmi?.length > 0) {
      const EmiDetail = getAllUsersEmi?.filter(
        (user) => user?.userId === parseInt(UserId)
      );

      setData(EmiDetail);
    }
  }, [getAllUsersEmi]);
  function formatNumber(value) {
    if (isNaN(value)) return "0.00";
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  console.log("datadata === >>>>>>", data);
  return (
    <div className=" w-full pb-10 mt-4">
      {data?.length > 0 ? (
        <div className="w-full overflow-x-auto">
          <table className="w-full border border-gray-400 text-left text-sm md:text-md table-auto">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="px-4 py-2 border">Headings</th>
                <th className="px-4 py-2 border">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">User Id</td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {data[0]?.userId}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Gross Salary
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {formatNumber(data[0]?.grossSalary)}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Include Mtg
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {data[0]?.includeMtg === true ? "True" : "False"}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Net Income
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {formatNumber(data[0]?.netIncome)}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Simah Liabilities
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {data[0]?.simahLibilities}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Total Dependents
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {data[0]?.totalDependents}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Final Eligible EMI
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {formatNumber(data[0]?.finalEligibleEMI)}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Domestic Worker
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {data[0]?.domesticWorker}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">
                  Disposable Income
                </td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {formatNumber(data[0]?.disposableIncome)}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-3 border whitespace-nowrap">Children</td>
                <td className="px-4 py-3 border whitespace-nowrap">
                  {" "}
                  {data[0]?.children}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="  items-center text-center mt-5 text-lg py-5 text-gray-600">
          {data?.length === 0 && loading ? (
            "Loading ..."
          ) : (
            <a className="text-xl">No data found !</a>
          )}
        </div>
      )}
    </div>
  );
}
export default Calculations;
let header = [
  { name: "User Id" },
  { name: "Gross Salary" },
  { name: "Include Mtg" },
  { name: "Net Income" },
  { name: "Simah Liabilities" },
  { name: "Total Dependents" },
  { name: "Final Eligible EMI" },
  { name: "Domestic Worker" },
  { name: "Disposable Income" },
  { name: "Children" },
];
